.wpo-menu-section {
  padding-bottom: 90px;

  .wpo-menu-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .wpo-menu-section-wrapper-content {
      width: 520px;

      .menu-section-wrapper-content-item-dish {
        margin-bottom: 16px;

        .dish-name {
          font-weight: 600;
          color: #000000;
        }

        .dish-description {
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
