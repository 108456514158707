.form {
    input,
    textarea,
    select {
        border-color: lighten( $black, 75% );
        outline: 0;
        box-shadow: none;
        color: lighten($black, 35%);
        font-style: normal;
    }

    @include placeholder-style(lighten($black, 35%));

    select {
        font-style: normal;
        background: url(../../images/select-icon.png) no-repeat right center;
        display: inline-block;
        -webkit-appearance:none;
        -moz-appearance:none;
        -ms-appearance:none;
        appearance:none;
        cursor:pointer;
    }

    select::-ms-expand { /* for IE 11 */
        display: none;
    }


    ::-webkit-input-placeholder { /* Edge */
        font-style: normal;
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-style: normal;
      }

      ::placeholder {
        font-style: normal;
      }

}

